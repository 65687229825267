export const timeSincePublished = (publishedAt) => {
  const givenDate = new Date(publishedAt);
  const now = new Date();
  const diffMs = now - givenDate;
  const diffSec = Math.floor(diffMs / 1000);
  const diffMin = Math.floor(diffSec / 60);
  const diffHrs = Math.floor(diffMin / 60);
  const currentYear = now.getFullYear();
  const givenYear = givenDate.getFullYear();

  // Less than an hour ago
  if (diffMin < 60) {
    return `${diffMin}min ago`;
  }

  // Between 1 hour and 2 hours ago
  if (diffHrs < 2) {
    return `1hr ago`;
  }

  // More than 2 hours ago but less than 24 hours ago
  if (diffHrs < 24) {
    return `${Math.round(diffHrs)}hrs ago`;
  }

  // More than 48 hours ago within the current year
  const options = { month: 'short', day: 'numeric' };
  if (diffHrs >= 24 && givenYear === currentYear) {
    return givenDate.toLocaleDateString('en-US', options);
  }

  // More than 48 hours ago not within the current year
  if (diffHrs >= 48 && givenYear !== currentYear) {
    options.year = 'numeric';
    return givenDate.toLocaleDateString('en-US', options);
  }
};
