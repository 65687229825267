import useSWRInfinite from 'swr/infinite';

import { getRecentPosts } from '@/lib/wordpress';
import { WpPost } from 'types/wp';

import { PostQueryKeys } from '../types';

const POSTS_PAGE_SIZE = 20;

export const usePostsReadPage = () => {
  return useSWRInfinite<WpPost[], unknown, (index: number, prevData: WpPost) => [string, number, number]>(
    (index) => {
      if (index === 0) return [PostQueryKeys.PostsReadPage, POSTS_PAGE_SIZE, 0];
      return [PostQueryKeys.PostsReadPage, POSTS_PAGE_SIZE, POSTS_PAGE_SIZE * index];
    },
    (_, limit, offset) => getRecentPosts(limit, offset),
    {
      revalidateOnMount: true,
      revalidateOnFocus: false,
    },
  );
};
