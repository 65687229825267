/* eslint-disable import/order */
/* eslint-disable import/no-unresolved */
import useSWRInfinite from 'swr/infinite';

import { getRecentPosts } from '@/lib/wordpress';
import { WpPost } from 'types/wp';

import { PostQueryKeys } from '../types';

const POSTS_PAGE_SIZE = 16;

export const usePosts = (leadPostIds?: string[], fallbackData?: WpPost[][]) => {
  return useSWRInfinite<WpPost[], unknown, (index: number, prevData: WpPost) => [string, number, number]>(
    (index) => {
      if (index === 0) return [PostQueryKeys.Posts, POSTS_PAGE_SIZE, 0];
      return [PostQueryKeys.Posts, POSTS_PAGE_SIZE, POSTS_PAGE_SIZE * index];
    },
    (_, limit, offset) => getRecentPosts(limit, offset, leadPostIds || []),
    {
      fallbackData,
      revalidateOnMount: false,
      revalidateOnFocus: false,
      initialSize: 3,
    },
  );
};
