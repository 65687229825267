import React from 'react';

import NextLink from 'next/link';

import styles from './sectionHeader.module.css';

export default function SectionHeader({ title, link = null }) {
  return (
    <div className={styles.sectionHeader}>
      <h2>{title}</h2>
      {link && (
        <NextLink href={link}>
          <a className={styles.sectionHeaderCTA}>
            View All
            <svg fill='none' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'>
              <path
                d='M5.5 11.3766L17.0535 11.3766L12.8628 7.0079C12.6416 6.77745 12.6416 6.40343 12.8628 6.17298C13.084 5.94234 13.4424 5.94234 13.6637 6.17298L18.7595 11.4853C18.9046 11.5923 19 11.7677 19 11.967C19 11.9701 18.9992 11.9729 18.9991 11.9759C18.9992 11.9824 18.9975 11.9885 18.9973 11.9949C18.9947 12.0527 18.9839 12.1078 18.9665 12.16C18.9563 12.1931 18.9416 12.223 18.9256 12.2543C18.9113 12.2808 18.8955 12.3059 18.8776 12.3297C18.8616 12.3518 18.8534 12.3778 18.8341 12.3979L13.6263 17.827C13.5156 17.9423 13.3707 18 13.2258 18C13.0809 18 12.936 17.9423 12.8254 17.827C12.6041 17.5964 12.6041 17.2227 12.8254 16.9921L17.0793 12.5575L5.5 12.5575C5.18732 12.5575 4.93362 12.2932 4.93362 11.967C4.93362 11.6409 5.18732 11.3766 5.5 11.3766Z'
                fill='#141414'
              />
            </svg>
          </a>
        </NextLink>
      )}
    </div>
  );
}
