/* eslint-disable import/no-unresolved */
/* eslint-disable import/order */
import React from 'react';

import cn from 'classnames';
import { useRouter } from 'next/router';
import { NewsItem } from './homepageTypes';

import { getABTestAnalyticData } from '@/lib/abTesting';
import { getUtmCookieData } from '@/lib/utm_cookie';

import s from './Homepage.module.css';
interface HpBreakingNewsProps {
  email: string;
  news: NewsItem;
}

const HomepageBreakingNews: React.FC<HpBreakingNewsProps> = function ({ email = null, news = null }) {
  const router = useRouter();

  const goto = function () {
    const abTestData = getABTestAnalyticData();
    const utmCookieData = getUtmCookieData();

    window.__DW_Next_Bridge?.Analytics.logEvent('Landing Page CTA Click', {
      ...abTestData,
      ...utmCookieData,
      destination: news?.ctaURL,
      email: email,
      label: news?.ctaText,
      location: 'Breaking News Banner',
    });

    router.push(news?.ctaURL || '/');
  };

  return (
    <div
      className={cn(s.breakingNewsContainer, {
        [s.breakingNewsOpen]: news !== null,
      })}
      onClick={goto}
    >
      <div className={s.breakingNews}>
        <div className={s.breakingNewsContent}>
          <div className={s.newsImage}>
            <img alt={news?.headline} src={news?.image.url} />
          </div>
          <div className={s.newsText}>
            <div className={s.newsTitle}>{news?.title}</div>
            <div className={s.newsHeadline}>{news?.headline}</div>
          </div>
        </div>
        <div className={s.breakingNewsCTA}>
          <span className={s.ctaText}>{news?.ctaText}</span>
          <RightArrow />
        </div>
      </div>
    </div>
  );
};

const RightArrow = function () {
  return (
    <svg fill='none' height='16' viewBox='0 0 18 16' width='18' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M10.4625 0L8.8875 1.67164L13.725 6.80597H0L0 9.19403H13.725L8.8875 14.3284L10.4625 16L18 8L10.4625 0Z'
        fill='white'
      />
    </svg>
  );
};

export default HomepageBreakingNews;
