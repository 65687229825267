import React from 'react';

import cn from 'classnames';

// eslint-disable-next-line import/no-unresolved, import/order
import { Episode } from '@/graphql/app';
// eslint-disable-next-line import/no-unresolved, import/order
import { debounce } from '@/utils/helpers';

import CarouselItem from './carousel-item';
import Header from './header';
import styles from './index.module.css';

interface CarouselProps {
  episodes: Episode[];
  title: string;
  href: string;
  backgroundColor: string;
  includePadding: boolean;
}

const Carousel2: React.FC<CarouselProps> = ({ backgroundColor, episodes, title, href = null, includePadding }) => {
  const [isStart, setIsStart] = React.useState(true);
  const [isEnd, setIsEnd] = React.useState(false);
  const ref = React.useRef<HTMLDivElement>(null);

  const handleScroll = React.useCallback(() => {
    if (ref.current) {
      setIsStart(ref.current.scrollLeft === 0);
      setIsEnd(ref.current.scrollLeft + ref.current.offsetWidth >= ref.current.scrollWidth);
    }
  }, []);

  const handlePrevious = React.useCallback(() => {
    if (ref.current) {
      const moveBy = Math.round(ref.current.scrollLeft / ref.current.offsetWidth) - 1;
      ref.current.scrollLeft = ref.current.offsetWidth * moveBy;
    }
  }, []);

  const handleNext = React.useCallback(() => {
    if (ref.current) {
      ref.current.scrollLeft += ref.current.offsetWidth;
    }
  }, []);

  React.useEffect(() => {
    const listener = debounce(handleScroll, 1000);

    window.addEventListener('resize', listener);
    handleScroll();

    return () => window.removeEventListener('resize', listener);
  }, [handleScroll]);

  return (
    <section
      className={styles.carouselSection}
      style={{
        backgroundColor: backgroundColor,
        padding: includePadding ? '48px 5% 48px 5%' : undefined,
      }}
    >
      <div className={styles.wrapper}>
        <Header href={href} title={title} />
        <div className={styles.carousel} onScroll={handleScroll} ref={ref}>
          {episodes &&
            episodes.map((episode) => {
              return <CarouselItem episode={episode} key={episode.id} />;
            })}
        </div>
        <button
          className={cn(styles.scrollButton, styles.left, {
            [styles.invisible]: isStart,
          })}
          onClick={handlePrevious}
        >
          <svg fill='none' height='24' viewBox='0 0 30 30' width='24' xmlns='http://www.w3.org/2000/svg'>
            <path
              clipRule='evenodd'
              d='M10.5871 6.83709C10.9532 6.47097 11.5468 6.47097 11.9129 6.83709L19.4129 14.3371C19.779 14.7032 19.779 15.2968 19.4129 15.6629L11.9129 23.1629C11.5468 23.529 10.9532 23.529 10.5871 23.1629C10.221 22.7968 10.221 22.2032 10.5871 21.8371L17.4242 15L10.5871 8.16291C10.221 7.7968 10.221 7.2032 10.5871 6.83709Z'
              fill='white'
              fillRule='evenodd'
            />
          </svg>
        </button>
        <button
          className={cn(styles.scrollButton, styles.right, {
            [styles.invisible]: isEnd,
          })}
          onClick={handleNext}
        >
          <svg fill='none' height='24' viewBox='0 0 30 30' width='24' xmlns='http://www.w3.org/2000/svg'>
            <path
              clipRule='evenodd'
              d='M10.5871 6.83709C10.9532 6.47097 11.5468 6.47097 11.9129 6.83709L19.4129 14.3371C19.779 14.7032 19.779 15.2968 19.4129 15.6629L11.9129 23.1629C11.5468 23.529 10.9532 23.529 10.5871 23.1629C10.221 22.7968 10.221 22.2032 10.5871 21.8371L17.4242 15L10.5871 8.16291C10.221 7.7968 10.221 7.2032 10.5871 6.83709Z'
              fill='white'
              fillRule='evenodd'
            />
          </svg>
        </button>
      </div>
    </section>
  );
};

export default Carousel2;
