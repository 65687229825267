import useSWRInfinite from 'swr/infinite';

import { getRecentPosts } from '@/lib/wordpress';
import { WpAuthor, WpPost } from 'types/wp';

import { PostQueryKeys } from '../types';

const POSTS_PAGE_SIZE = 12;

export const usePostsByAuthor = (author: WpAuthor, fallbackData: WpPost[][]) => {
  return useSWRInfinite<WpPost[], unknown, (index: number, prevData: WpPost) => [string, number, number, number]>(
    (index) => {
      if (index === 0) return [PostQueryKeys.PostsByAuthor, POSTS_PAGE_SIZE, 0, author.id];
      return [PostQueryKeys.PostsByAuthor, POSTS_PAGE_SIZE, POSTS_PAGE_SIZE * index, author.id];
    },
    (_, limit, offset, author) => getRecentPosts(limit, offset, [], { author }),
    {
      fallbackData,
      revalidateOnMount: false,
      revalidateOnFocus: false,
    },
  );
};
