export const liveShows = {
  candace: true,
  'crain-and-company': true,
  'daily-wire-backstage': true,
  'daily-wire-townhall': true,
  'the-andrew-klavan-show': true,
  'the-ben-shapiro-show': true,
  'the-matt-walsh-show': true,
  'the-michael-knowles-show': true,
};
