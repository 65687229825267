import useSWRInfinite from 'swr/infinite';

import { getRecentPosts } from '@/lib/wordpress';
import { WpPost, WpTag } from 'types/wp';

import { PostQueryKeys } from '../types';

const POSTS_PAGE_SIZE = 12;

export const usePostsByTopic = (topic: WpTag, fallbackData: WpPost[][]) => {
  return useSWRInfinite<WpPost[]>(
    (index) => {
      if (index === 0) return [PostQueryKeys.PostsByTopic, POSTS_PAGE_SIZE, 0, topic.id];
      return [PostQueryKeys.PostsByTopic, POSTS_PAGE_SIZE, POSTS_PAGE_SIZE * index, topic.id];
    },
    (_, limit, offset, topic) => getRecentPosts(limit, offset, [], { tags: topic }),
    {
      fallbackData,
      revalidateOnMount: false,
      revalidateOnFocus: false,
    },
  );
};
