import { useMedia } from 'react-use';

/* Predefined breakpoints */

export function useBreakpoints(
  HDLowerLimit = 1200,
  desktopLowerLimit = 1000,
  desktopUpperLimit = 1199,
  tabletLowerLimit = 750,
  tabletUpperLimit = 999,
  phabletLowerLimit = 550,
  phabletUpperLimit = 749,
  mobileUpperLimit = 549,
) {
  // Use react-use to determine the current screen size, add in modifier as needed for edge cases
  const isHD = useMedia(`(min-width: ${HDLowerLimit}px`);
  const isDesktop = useMedia(`(min-width: ${desktopLowerLimit}px) and (max-width: ${desktopUpperLimit}px)`);
  const isTablet = useMedia(`(min-width: ${tabletLowerLimit}px) and (max-width: ${tabletUpperLimit}px)`);
  const isPhablet = useMedia(`(min-width: ${phabletLowerLimit}px) and (max-width: ${phabletUpperLimit}px)`);
  const isMobile = useMedia(`(max-width: ${mobileUpperLimit}px)`);

  return { isHD, isDesktop, isTablet, isPhablet, isMobile };
}
