/* eslint-disable import/no-unresolved */
/* eslint-disable import/order */
import React from 'react';

import NextLink from 'next/link';

import { Container, ThreeColumnGrid, LoadingComponent } from '@/components/design-system';
import { WpPost } from 'types/wp';
import { NewsItem } from './homepageTypes';

import HomepageCarousel from './HomepageCarousel';
import HomepageTopStories from './HomepageTopStories';

interface HpHeroProps {
  email: string;
  featuredContent: any[];
  featuredPosts: WpPost[];
  news: NewsItem[];
  loading: boolean;
}

const HomepageHero: React.FC<HpHeroProps> = function ({ email = null, featuredContent, featuredPosts, news, loading }) {
  const marginValue = news ? 16 : 32;
  function handleFallbackImageClick() {
    window.__DW_Next_Bridge?.Analytics.logEvent('Landing Page CTA Click', {
      destination: `/news/${featuredPosts[0]?.slug ?? ''}`,
      email: email,
      label: '',
      location: 'Carousel Image CTA',
      referrer: '/',
    });
  }
  return (
    <Container
      css={(theme) => ({
        marginTop: 0,
        padding: 0,
        [theme.mq.tablet]: {
          marginTop: marginValue,
          padding: '0 5%',
        },
      })}
    >
      <ThreeColumnGrid>
        {loading ? (
          <div
            style={{
              width: '100%',
              height: '100%',
              minHeight: '420px',
              gridColumn: '1 / span 2',
              position: 'relative',
            }}
          >
            <LoadingComponent />
          </div>
        ) : (
          <>
            {featuredContent && featuredContent.length > 1 ? (
              <HomepageCarousel email={email} featuredContent={featuredContent} />
            ) : (
              <NextLink href={featuredPosts[0]?.slug ? `/news/${featuredPosts[0].slug}` : '/'}>
                <div
                  onClick={handleFallbackImageClick}
                  style={{
                    gridColumn: '1 / span 2',
                  }}
                >
                  <img
                    alt={featuredPosts[0]?.image?.alt ?? 'Featured Post Image'}
                    src={featuredPosts[0]?.image?.url ?? '/images/default_logo.jpg'}
                    style={{
                      width: '100%',
                      height: '100%',
                      objectFit: 'cover',
                      objectPosition: 'center',
                      cursor: 'pointer',
                    }}
                  />
                </div>
              </NextLink>
            )}
          </>
        )}
        <HomepageTopStories featuredPosts={featuredPosts} />
      </ThreeColumnGrid>
    </Container>
  );
};

export default HomepageHero;
