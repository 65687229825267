const punctuationRegEx = /\W*$/;
const memberExclusiveRegEx = /(\[?member exclusive\]?)|(\[?ad free\]?)/i;
const memberExclusiveAccess = ['INSIDER', 'INSIDER_PLUS', 'ALL_ACCESS'];

export function filterMemberExclusiveEpisodes(episodes, isAuthenticated, subscriber, episodeKey = null) {
  if (!episodes) return episodes;

  if (isAuthenticated && subscriber && memberExclusiveAccess.includes(subscriber.effectivePlan)) {
    return showMemberExclusive(episodes, episodeKey);
  }

  return removeMemberExclusive(episodes, episodeKey);
}

function removeMemberExclusive(episodes, episodeKey) {
  return episodes.filter(function (episode) {
    const ep = getEpisode(episode, episodeKey);
    if (ep && memberExclusiveRegEx.test(ep.title)) {
      return false;
    }
    return true;
  });
}

function showMemberExclusive(episodes, episodeKey) {
  const { filteredList } = episodes.reduce(
    function (data, episode, index) {
      if (data.skipNext) {
        data.skipNext = false;
        return data;
      }

      const ep = getEpisode(episode, episodeKey);

      if (ep && memberExclusiveRegEx.test(ep.title)) {
        const meTitle = ep.title.replace(memberExclusiveRegEx, '').replace(punctuationRegEx, '').trim();
        const nextEp = getEpisode(episodes[index + 1], episodeKey);
        const prevEp = getEpisode(episodes[index - 1], episodeKey);

        if (nextEp && nextEp.title.replace(punctuationRegEx, '').trim() === meTitle) {
          data.skipNext = true;
        } else if (prevEp && prevEp.title.replace(punctuationRegEx, '').trim() === meTitle) {
          data.filteredList.pop();
        }
      }

      data.filteredList.push(episode);

      return data;
    },
    { filteredList: [], skipNext: false },
  );

  return filteredList;
}

function getEpisode(episode, key) {
  if (episode) {
    if (key) {
      return episode[key] || null;
    }
    return episode;
  }
  return null;
}
