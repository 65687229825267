import React from 'react';

import format from 'date-fns/format';
import NextLink from 'next/link';

// eslint-disable-next-line import/no-unresolved
import { Icon } from '@/components/design-system';
// eslint-disable-next-line import/no-unresolved
import { Episode } from '@/graphql/app';
// eslint-disable-next-line import/no-unresolved
import { isUpcomingDate } from '@/utils/helpers';

// eslint-disable-next-line import/order
import styles from './index.module.css';

interface CarouselItemProps {
  episode: Episode;
}

const carouselItem: React.FC<CarouselItemProps> = ({ episode }) => {
  const isUpcomingEpisode = isUpcomingDate(episode.scheduleAt ? episode.scheduleAt : false);
  function getReleaseDate(date: string): string {
    const releaseDate = new Date(date);
    const options = { year: 'numeric', month: 'short', day: 'numeric' };
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return releaseDate.toLocaleDateString('en-US', options);
  }
  return (
    <div className={styles.carouselItem}>
      <NextLink href={`/episode/${episode?.slug}`}>
        <a>
          <div className={styles.carouselThumbailContainer}>
            <img alt={episode?.title} src={episode?.image} />
            <div className={styles.playButton}>
              <svg fill='none' height='16' viewBox='0 0 16 16' width='16' xmlns='http://www.w3.org/2000/svg'>
                <path
                  d='M4 2L13.3333 8L4 14V2Z'
                  fill='white'
                  stroke='white'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                />
              </svg>
            </div>
          </div>
          <div className={styles.carouselItemText}>
            <h3>{episode?.title}</h3>
            <p>
              {isUpcomingEpisode && <Icon css={{ color: '#2F78D2', marginRight: '8px' }} name='CLOCK' size={16} />}{' '}
              {`${episode?.show?.name} • ${getReleaseDate(
                episode?.scheduleAt ? episode?.scheduleAt : episode?.createdAt,
              )}`}{' '}
              {isUpcomingEpisode && episode.scheduleAt && format(new Date(episode.scheduleAt), " 'at' h:mma")}
            </p>
          </div>
        </a>
      </NextLink>
    </div>
  );
};

export default carouselItem;
